'use client'

import React, { useState, Suspense, useContext } from 'react';
import { AuthContext } from '@/lib/contexts/authContext';
import { SDKWrapper } from '@/lib/helpers/directus/SDKWrapper';
import { usePathname } from 'next/navigation'

import { Card, Button, Notification, Popup, Icon } from '@/components/capacitor';

import {
	App,
	Page,
    Navbar,
    List,
    ListInput
} from 'konsta/react';

import Loading from '@/components/Loading';

import { guestPaths } from '@/lib/constants/middlewarePaths';

import TabBar from '@/components/global/TabBar';

import { useRouter } from 'next/navigation';

export default function Template({ children }) {
    const { localStorageObject, setLocalStorageObject } = useContext(AuthContext);

    const [bugReportPopup, setBugReportPopup] = useState(false);
    const [bugReportMessage, setBugReportMessage] = useState('');
    const [bugReportSent, setBugReportSent] = useState(false);
    const [bugReportError, setBugReportError] = useState(false);

    const sdkWrapper = new SDKWrapper(localStorageObject, setLocalStorageObject);

    async function submitBugReport(e) {
        e.preventDefault();

        let userEmail = 'Not found';
        if (window.localStorage) {
            userEmail = window.localStorage.getItem('userEmail') || 'Not found';
        }

        const bugReportData = {
            user_email: userEmail,
            message: bugReportMessage,
            timestamp: new Date().toISOString(),
        }

        console.log('Sending bug report:', bugReportData);
        
        setBugReportPopup(false);

        try {
            const response = await sdkWrapper.createBugReport(bugReportData);
            
            setBugReportMessage('');
            setBugReportSent(true);
            setBugReportError(null);

        } catch (error) {
            console.error('Error:', error);
            setBugReportMessage('');
            setBugReportSent(false);
            setBugReportError(error);
        }
    }

    const pathname = usePathname()
    
    const [needsAuth] = useState(!guestPaths.includes(pathname));

    const router = useRouter()

    return (
        <Suspense>
            <App theme="material" style={{
                minHeight: '100dvh',
            }}>
                <Page className='flex flex-col bg-primary-50 dark:bg-primary-50'>
                    <Navbar colors={{bgMaterial: 'bg-primary-300 dark:bg-primary-300'}} style={{ zIndex: 999, background: "transparent" }} className='px-4 w-full flex' left={(
                        <img src='/images/Logo wit.png' alt='logo' className='h-20 cursor-pointer' onClick={() => { router.push('/') }} />
                    )} right={(
                        <div className='h-10 w-10 p-2 cursor-pointer rounded-full bg-primary-100 text-primary-800 flex opacity-75' onClick={() => {setBugReportPopup(true)}}>
                            <Icon className="m-auto" size={36} name={'MdOutlineWarning'} />
                        </div>
                    )} />
                    
                    <Notification
                        opened={bugReportSent}
                        setOpened={setBugReportSent}
                        closeCallback={() => {}}
                        title="Foutmelding verstuurd"
                        titleRightText="now"
                        text="Bedankt voor het melden van het probleem. We zullen het zo snel mogelijk oplossen."
                    />
                    <Notification
                        opened={bugReportError}
                        setOpened={setBugReportError}
                        title="Foutmelding niet verstuurd"
                        text={bugReportError}
                        className="bg-red-500 dark:bg-red-700"
                    />
                    
                    <Popup
                        size={`${bugReportPopup ? 'w-[90vw] md:w-[40vw]' : 'h-0'}`}
                        colors={{
                            bg: "bg-transparent",
                        }}
                        opened={bugReportPopup}
                        onBackdropClickHook={() => setBugReportPopup(false)}
                    >
                        <Card raised style={{ zIndex: 99, margin: 0, padding: 0 }} className={`m-auto max-h-96 overflow-y-auto dark:bg-danger-100 relative p-0 flex flex-col justify-center items-center`}>
                            <form onSubmit={submitBugReport} className='flex flex-col gap-5 items-center'>
                                <div className='max-w-64'>
                                    <h4 className='text-center text-primary-800'>Beschrijf het probleem in het onderstaande veld:</h4>
                                </div>

                                <List className="w-full" margin='m-0'>
                                    <ListInput
                                        value={bugReportMessage}
                                        onChange={(e) => setBugReportMessage(e.target.value)}
                                        className='w-full'
                                        name="name"
                                        label="Beschrijf het probleem"
                                        type="textarea"
                                        placeholder="Beschrijf het probleem..."
                                        required
                                    />
                                </List>
                                <div className="flex gap-3">
                                    <Button type='button' colorType='danger' onClick={() => setBugReportPopup(false)} className="w-full mt-4 text-nowrap whitespace-nowrap">Sluiten</Button>
                                    <Button type='submit' className="w-full mt-4 text-nowrap whitespace-nowrap">Versturen</Button>
                                </div>
                            </form>
                        </Card>
                    </Popup>
                    
                    {localStorageObject.loading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className={`${needsAuth ? 'pb-24' : 'pb-0'} w-full flex-1 px-4 overflow-x-hidden`}>
                                {children}
                            </div>

                            {needsAuth && (
                                <TabBar />
                            )}
                        </>
                    )}
                </Page>
            </App>
        </Suspense>
	);
}